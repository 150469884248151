<template>
    <v-list>
        <template v-for='node in myNodes'>
            <v-list-group v-if='nodes && nodes.filter(p=>p.parent_id == node._id).length > 0' :key="node.name" v-model="node.active" @click.stop="">
                <template v-slot:activator>
                    <v-list-item @click.stop="goToRoute(node)">
                        <v-list-item-title>{{node.name}}</v-list-item-title>
                    </v-list-item>
                </template>
                <node-list class='py-0 pl-3' :nodes='nodes' :parent_ids="parentIds(node)" :collapsed="true"/>
            </v-list-group>
            <v-list-item class='py-0 pl-8' @click="goToRoute(node)" v-else :key="node.name">
                <v-list-item-title>{{node.name}}</v-list-item-title>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    name: 'node-list',
    props: ['nodes', 'parent_ids', 'collapsed'],    
    computed: {
        myNodes(){     
            if(!this.parent_ids.includes(null))
                return this.nodes.filter(p => this.parent_ids.includes(p._id)).sort((a, b) => a.name.localeCompare(b.name));
            return this.nodes.filter(p => p.parent_id === null).sort((a, b) => a.name.localeCompare(b.name));
        }
    },    
    methods: {
        toggle(node, toggle){
            node.active = toggle;                    
        },
        goToRoute(node){  
            const route = "/products/"+node.name;
            if (this.$route.path != route) {
                this.$router.push(route);
            } 
        },
        parentIds(menuItem){
            let nodes = this.nodes.filter(p => p.parent_id === menuItem._id);
            let parent_ids = [];
            for(let node of nodes){
                parent_ids.push(node._id);                
            }
            return parent_ids;
        }
    }
}
</script>

<style scoped>
.theme--dark.v-list {
  background: #403b37;
  border-color: #403b37;
}
</style>