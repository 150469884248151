export default function () {
    return {
        products: [],
        productsCount: 0,
        product: {},
        colors: [],
        subcategories: [],
        sizes: [],
        genders: [],
        selectedSubcategory: 0,
        selectedSize: 0,
        selectedColor: 0
    }
}