<template>
  <v-container :style="{ 'max-width': '1340px', padding: '30px' }">
    <v-row class="ma-0 pa-0">
      <p class="title">O seu carrinho</p>
    </v-row>
    <div v-if="cart.length > 0">
    <v-row class="ma-0 pa-0" v-if="!isMobile()">
      <v-col cols="2" offset="6" class="text-right pa-0 text">Preço</v-col>
      <v-col cols="2" class="text-right pa-0 text">Quantidade</v-col>
      <v-col cols="2" class="text-right pa-0 text">Total</v-col>
    </v-row>
    <v-row
      class="ma-0 mt-5 pa-0 pt-5 product-line"
      v-for="cartProduct in cart"
      :key="cartProduct.variant._id"
    >
      <v-col class="pa-0" cols="5" sm="3">
        <router-link
          :to="{ name: 'productdetailvariant', params: { id: cartProduct.product.ref, variant: cartProduct.variant.id } }"
        >
        <v-img
          max-height="150"
          max-width="150"
          :src="smallImage(cartProduct.variant.photos[0])"
        />
        </router-link>
      </v-col>
      <v-col class="pa-0 pl-2" cols="7" sm="3">
        <router-link
          :to="{ name: 'productdetailvariant', params: { id: cartProduct.product.ref, variant: cartProduct.variant.id } }"
        >
        <p class="title text">{{cartProduct.product.title}}</p>
        <p class="text">{{cartProduct.variant.size}} / {{cartProduct.variant.color}}</p>
        </router-link>
        <v-btn
            class="mt-5"
            text
            color="error"
            @click="removeProductFromCartBtn(cartProduct.variant._id)"
        >Eliminar</v-btn>
      </v-col>

      <v-col cols="4" class="text-left pa-0 mt-6 text" v-if="isMobile()">
        Preço
      </v-col>
      <v-col cols="4" class="text-center pa-0 mt-6 text" v-if="isMobile()">
        Quantidade
      </v-col>
      <v-col cols="4" class="text-right pa-0 mt-6 text" v-if="isMobile()">
        Total
      </v-col>

      <v-col cols="5" sm="2" :class="!isMobile() ? 'text-right' : 'text-left'" class="pa-0">
          <p class="nowrap text">{{cartProduct.variant.price_final.toFixed(2).replace('.',',')}} €</p>
      </v-col>
      <v-col cols="2" offset="0" offset-sm="1" sm="1" offset-md="1" md="1" :class="isMobile() ? 'text-center' : 'text-right'" class="pa-0">
          <v-text-field
              v-model="cartProduct.quantity"
              outlined              
              hide-details
              type="number"
              min="1"
              dense
              align="center"
              align-self="center"
              :rules="[rules.quantityValidator(cartProduct.variant.qty)]"
              :class="!isMobile() ? 'right-aligned input-number-field' : 'input-number-field'"
            />
      </v-col>
      <v-col cols="5" sm="2" class="text-right pa-0">
          <p class="nowrap text">{{(cartProduct.quantity * cartProduct.variant.price_final).toFixed(2).replace('.',',')}} €</p>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 mt-5 pa-0 pt-5 product-line"      
    >
      <v-col cols="12" sm="4" offset-sm="8" class="text-right pa-0">
        <p class="text text-content">Subtotal<span class="text pl-5">{{totalPrice.toFixed(2).replace('.',',')}} €</span></p>
        <p class="text text-content tax-text">Imposto incluído e envio calculado na finalização da compra</p>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 mt-5 pa-0"      
    >
      <v-col cols="12" sm="6" offset-sm="6" class="text-right pa-0">
        <v-btn
          outlined
          color="#e4d69f"
          @click="continueShopping"
        >
          Continuar a escolher
        </v-btn>
        <v-btn
          :class="isMobile() ? 'mt-5': ''"
          class="ml-5"          
          color="#e4d69f"
          @click="confirmOrder"
        >
          Finalizar a encomenda
        </v-btn>
      </v-col>
    </v-row>
    </div>
    <div v-else>
      <v-row class="ma-0 pa-0">
      <v-col cols="12" class="text-left pa-0 text cart-text">
        <p>De momento o seu carrinho está vazio.</p>
        <p>Continue a ver <router-link :to="{ name: 'home'}">aqui.</router-link></p>
      </v-col>      
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("cart", ["cart", "totalPrice"]),
  },
  methods: {
    ...mapActions("cart", ["removeProductFromCart", "updateProductCartQuantity"]),
    removeProductFromCartBtn(_id){
        this.removeProductFromCart({_id});
    },
    isMobile() {
      return this.windowWidth < 600;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    continueShopping() {
      this.$router.push('/');
    },
    confirmOrder(){
      this.$router.push('/order');
    },
    smallImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_s.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_s.png');
      return url;
    } 
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
        windowWidth: window.innerWidth,
        rules: {
            quantityValidator(variationQty) {                                
                return v => variationQty >= v;
            }
        },
    };
  }
};
</script>


<style scoped>
.title {
  color: black;
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 700;
  margin-top: 10px;
}

p {
  margin: 10px 0 0 0;
}

a {
  text-decoration: none;
}

.product-line {
    border-top: 1px solid gray;
}

.nowrap {
  display: inline-block;
  white-space: nowrap;
  margin-top: 0px;
  font-weight: 500;
}

.right-aligned{  
  float: right;
}

.input-number-field {
  width: 60px;
  min-width: 60px;
  display: inline-block;
}

.tax-text {
  font-size: 0.9em !important;
  font-style: italic;
}

.cart-text {
  font-weight: 500;
}

</style>
