<template>
    <v-form ref="form">
        <v-container>
            <v-row no-gutters align="center" justify="center" style="text-align:center;flex-direction:column;">
                <div>
                    <h1>A família Trevo Dourado está a melhorar este espaço para si...</h1>
                </div>
                <div>
                    <h2>Vamos voltar com artigos da nova coleção e serviços à sua medida!</h2>
                </div>
            </v-row>
            <v-row no-gutters align="center" justify="center" class="mt-16">                
                <v-col cols="6" sm="3" align="center" justify="center">                
                <v-text-field
                    v-model="password"
                    :rules="[rules.required]"                     
                    label="Password"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    outlined
                />
                <v-btn
                    color="#e4d69f"                
                    @click="loginOperation">
                    Entrar
                </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "InPreview",
    data: () => ({
        value: true,
        password: '',
        rules: {
            required: (value) => !!value || "Campo obrigatório",            
        },
    }),
    methods: {
        ...mapActions("config", ["loginAfterLivePrompt"]),
        loginOperation(){
            if (this.$refs.form.validate()){
                this.loginAfterLivePrompt({                    
                    password: this.password
                }).then(() => {
                    this.$router.push('/');
                });
            } else {
                //show error                
            }
        }
    }
}
</script>