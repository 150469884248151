<template>
  <v-container :style="{ 'max-width': '1340px', padding: '30px' }">

    <v-overlay :value="loading" opacity="0.9">
      <v-row no-gutters align="center" justify="center">
        <v-progress-circular
          color="#e4d69f"
          v-if="loading"
          indeterminate
          size="128"        
        >
        </v-progress-circular>
      </v-row>
    </v-overlay>

    <v-alert        
        v-if="error"
        color="red"
        dismissible        
        type="error"
        @input="redirect"
        >{{ errorMsg }}</v-alert
      >

    <v-alert        
        v-if="success"
        color="green"
        dismissible   
        @input="redirect"     
        type="success"
        >A sua encomenda foi submetida com sucesso, será contactado brevemente.</v-alert
      >

    <div v-if="!success && !error">
        <v-row no-gutters>
            <p class="title">Os seus dados para a encomenda</p>
        </v-row>
        <p style="font-style:italic;">Ao submeter a sua encomenda, irá receber um email apenas com a informação da mesma e os próximos passos a seguir.</p>        
        <v-row>        
            <v-col cols="12" sm="6">
                <v-form v-model="valid" ref="form">
                    <v-row no-gutters>
                        <p class="title">Método de compra</p>
                    </v-row>
                    <v-row no-gutters>        
                        <v-col cols="12" sm="6">
                            <v-radio-group v-model="sendMethod" :rules="[rules.radioSelected]">
                                <v-radio                                     
                                    label="Levantamento em Loja"
                                    value="0"
                                ></v-radio>
                                <v-radio                                                                
                                    label="Envio por Correio"
                                    value="1"
                                ></v-radio>                                
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <div v-if="sendMethod != -1">
                        <p class="title">Informações de contacto</p>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    label="Email"                
                                    outlined
                                    v-model="email"
                                    hide-details
                                    :rules="[rules.required, rules.isEmail]"
                                ></v-text-field>
                            </v-col>        
                            <v-col cols="6">
                                <v-text-field
                                    label="Número de telemóvel"          
                                    outlined 
                                    v-model="number"
                                    hide-details
                                    :rules="[rules.required, rules.isNumber]"
                                ></v-text-field>
                            </v-col>        
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    label="Nome"                
                                    outlined
                                    v-model="firstName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>        
                            <v-col cols="6">
                                <v-text-field
                                    label="Apelido"          
                                    outlined
                                    v-model="lastName"
                                    hide-details
                                    :rules="[rules.required]"             
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="sendMethod === '1'">
                        <p class="title">Endereço de envio</p>                            
                        <v-row class="my-0 py-0">
                            <v-col cols="3">
                                <v-text-field
                                    label="NIF"                
                                    outlined
                                    v-model="fiscalNumber"
                                    hide-details                            
                                ></v-text-field>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    label="Endereço"
                                    outlined
                                    v-model="address"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col cols="12">
                                <v-text-field
                                    label="Nº Porta/Apartamento/Fração, etc. (opcional)"
                                    outlined
                                    v-model="door"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col cols="6">
                                <v-text-field
                                    label="Código postal"
                                    outlined
                                    v-model="postalCode"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Cidade"
                                    outlined
                                    v-model="city"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                        </v-row>                        
                    </div>                                       
                </v-form>
            </v-col>
            <v-col cols="12" sm="6">
                <p class="sub-title">Detalhes da encomenda</p>            
                <v-row class="ma-0 mb-3"              
                    v-for="cartProduct in cart"
                    :key="cartProduct.variant._id"
                    >
                    <v-col cols="2" class="ma-0 pa-0" sm="2">                    
                        <div style="position:relative;max-width:50px;max-height:50px;">
                            <v-img
                                class="rounded"                   
                                max-height="50"
                                max-width="50"
                                :src="smallImage(cartProduct.variant.photos[0])"
                            />
                            <div class="quantity">
                                {{cartProduct.quantity}}
                            </div>
                        </div>                                      
                    </v-col>
                    <v-col class="ma-0 pa-0 pl-2" cols="6" sm="8" >                    
                        <div>{{cartProduct.product.title}}</div>
                        <div>{{cartProduct.variant.size}} / {{cartProduct.variant.color}}</div>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="4" sm="2">
                        <div class="nowrap">{{(cartProduct.quantity * cartProduct.variant.price_final).toFixed(2).replace('.',',')}} €</div>
                    </v-col>
                </v-row>
                <v-row class="ma-0 flex-column" align="end">
                    <p class="text text-content mb-1">Subtotal<span class="text pl-5">{{ totalPrice.toFixed(2).replace('.',',') }} €</span></p>
                    <p v-if="sendMethod === '1'" class="text text-content mb-1">Taxa de Envio<span class="text pl-5">{{ shippingCost.toFixed(2).replace('.',',') }} €</span></p>
                    <p class="text text-content">Total<span class="text pl-5">{{ calculateTotalPrice() }} €</span></p>                    
                </v-row>
            </v-col>
        </v-row>
        <v-row class="pl-0 ml-0">       
            <p style="font-style:italic;">Assim que confirmada a disponibilidade da sua encomenda 
                        receberá os dados para o levantamento ou pagamento.</p>
             <p style="font-style:italic;">
                        A sua reserva tem um período de 3 dias, para ser paga ou levantada, 
                        após esse período será cancelada.</p>            
        </v-row>
        <v-row align="center" justify="center" class="mt-10">            
            <v-btn v-if="!showChallenge" color="#e4d69f" @click="validateAndShowChallenge">
                    Encomendar
            </v-btn>
            <vue-recaptcha v-if="showChallenge" ref="recaptcha" @verify="onVerify" :loadRecaptchaScript="true" :sitekey="recaptchaKey">                
            </vue-recaptcha>           
        </v-row>
    </div>
  </v-container>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';
import {  mapActions, mapGetters } from "vuex";

export default {
  components: {
      'vue-recaptcha': VueRecaptcha
  },
  computed: {
    ...mapGetters("cart", ["cart", "totalPrice", "success", "error", "errorMsg"]),
    ...mapGetters("config", ["shippingCost"]),
  },
  data: () => ({
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_CLIENT_KEY,
    showChallenge: false,
    email: null,
    number: null,
    firstName: null,
    lastName: null,
    fiscalNumber: null,
    address: null,
    door: null,
    postalCode: null,
    city: null,
    valid: false,
    loading: false,    
    sendMethod: -1,
    rules: {        
        required: (value) => !!value || "Campo obrigatório",
        isNumber: (value) => !!value && !isNaN(value) || "Número inválido",
        isEmail: (value) => /.+@.+\..+/.test(value) || "Email inválido",
        radioSelected: (value) => { return parseInt(value) >= 0 || "Seleccione opção" },        
    },
  }),
  mounted(){
    this.$store.commit("cart/clearSendOrderState");
  },
  methods: {
    ...mapActions("cart", ["submitOrder"]),   
    smallImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_s.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_s.png');
      return url;
    },
    onVerify(token){
        this.submitOrderHandler(token)
    },
    validateAndShowChallenge(){
        if(this.$refs.form.validate()){         
            this.showChallenge = true;
        }
    },   
    submitOrderHandler(token){     
        if(this.$refs.form.validate()){            
            this.$store.commit("cart/clearSendOrderState");
            this.loading = true;
            this.submitOrder({
                token: token,                
                sendMethod: this.sendMethod,
                email: this.email,
                number: this.number,
                firstName: this.firstName,
                lastName: this.lastName,
                fiscalNumber: this.fiscalNumber,
                address: this.address,
                door: this.door,
                postalCode: this.postalCode,
                city: this.city,
                products: this.cart.map(p => { return  { variant_id: p.variant._id, quantity: p.quantity, ref: p.product.ref  }})
            }).then(() => {
                this.loading = false;
                this.$store.commit("cart/clearCart");
            }).catch(() => {
                this.loading = false;
            });
        }
    },
    redirect(){
        this.$store.commit("cart/clearSendOrderState");
        this.$router.push('/');
    },
    calculateTotalPrice(){
        if(this.sendMethod === '1')
            return (this.totalPrice + this.shippingCost).toFixed(2).replace('.',',');
        return this.totalPrice.toFixed(2).replace('.',',');
        
    }
  },
}
</script>



<style scoped>
.title {
  color: black;
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 700;
  margin-top: 10px;
}

.sub-title {
  color: black;
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  font-size: 1.2em;
  line-height: 1.4;
  font-weight: 500;
  margin-top: 10px;
}

.nowrap {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  margin-top: 0px;
  font-weight: 500;
}

.quantity {
    font-size: 0.8571428571em;
    font-weight: 500;
    line-height: 1.75em;
    white-space: nowrap;
    text-align: center;
    border-radius: 1.75em;
    background-color: rgba(114,114,114,0.9);
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 1.75em;
    height: 1.75em;
    padding: 0 0.5833333333em;
    position: absolute;
    right: -0.75em;
    top: -0.75em;
    z-index: 3;
}

</style>