
import Vue from 'vue';
import Vuex from 'vuex';
import product from './product';
import menu from './menu';
import cart from './cart';
import config from './config';

Vue.use(Vuex)

const Store = new Vuex.Store({
  modules: {
    product,
    menu,
    cart,
    config
  }    
})

export default Store;