import Axios from 'axios';
export async function isLive({ commit }) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/live/status`;
    let response = await Axios.get(url);
    if(response.data){
        commit("setIsLive", response.data.isLive);
        commit("setShippingCost", response.data.shippingCost);
    }
}

export async function loginAfterLivePrompt({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/live/login`;
    return Axios.post(url, payload, { withCredentials: true }).then(response => {    
        if(response.data && response.data.success === true){
            commit("setBypassed", true);
        } else {
            commit("setBypassed", false);
        } 
    }).catch(() => {
        commit("setBypassed", false);
    });
}

export async function verify({ commit }) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/live/verify`;
    return Axios.get(url, { withCredentials: true }).then(response => {
        if(response.data && response.data.success === true){
            commit("setBypassed", true);
        } else {
            commit("setBypassed", false);
        }
    }).catch(() => {
        commit("setBypassed", false);
    })
}