export function setProducts(state, val) {
    state.products = val
}

export function setProductsCount(state, val) {
    state.productsCount = val
}

export function setProductDetail(state, val) {
    state.product = val
}

export function setColors(state, val) {
    state.colors = val
}

export function setSubcategories(state, val) {
    state.subcategories = val;
}

export function setGenders(state, val) {
    state.genders = val;
}

export function setSizes(state, val) {
    state.sizes = val;
}

export function setPreviousState(state, val){
    state.selectedSubcategory = val.subcategory;
    state.selectedSize = val.size;
    state.selectedColor = val.color;
    state.selectedGender = val.gender;    
}