import Axios from 'axios';

export function addToCart({ commit }, payload) {
    if(payload.product && payload.quantity && payload.variant){        
        commit("addProductToCart", payload);
        commit("saveCart");
    }    
}

export function removeProductFromCart({ commit }, payload) {
    if(payload._id){
        commit("removeProductById", payload);
        commit("saveCart");
    }        
}

export function updateProductCartQuantity({ commit }, payload) {
    if(payload._id && payload.quantity){
        commit("updateProductQuantity", payload);
        commit("saveCart");
    }        
}

export function submitOrder({commit}, payload){
    let url = `${process.env.VUE_APP_API_ENDPOINT}/order`;     
    return Axios.post(url, payload)
    .then(response => {
        if(response.data.success === true){ 
            commit("success");
        } else if(response.data.error === true){ 
            commit("error", response.data.errorMsg);
        }
    })
    .catch(function() {        
        commit("error");        
    });
}