export function products (state) {
    return state.products
}

export function productsCount (state) {
    return state.productsCount;
}

export function product (state) {
    return state.product;
}

export function colors (state) {
    return state.colors;
}

export function subcategories (state) {
    return state.subcategories;
}

export function sizes (state) {
    return state.sizes;
}

export function genders (state) {
    return state.genders;
}

export function previousColor(state){
    return state.selectedColor;
}

export function previousSize(state){
    return state.selectedSize;
}

export function previousSubcategory(state){
    return state.selectedSubcategory;
}