export function setIsLive(state, val) {
    state.isLive = val;
}

export function setShippingCost(state, val) {
    state.shippingCost = val;
}

export function setBypassed(state, val) {
    state.isByPassed = val;
}