import Axios from 'axios';
export async function fetchProducts({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products`;    
    let response = await Axios.get(url, { params: { separator: payload.separator, page: payload.pageRequested, limit: payload.amountProductsRequested } });
    if(response.data){
        commit("setProducts", response.data.products);
        commit("setProductsCount", response.data.count);
    }
}

export async function fetchProduct({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/product-details`;    
    let response = await Axios.get(url, { params: { id: payload } });  
    if(response.data){
        commit("setProductDetail", response.data);
    }
}

export async function fetchColors({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products/colors`;    
    let response = await Axios.get(url, { params: { separator: payload.separator } });
    if(response.data){
        response.data.colors.unshift({name:'Todos'});
        response.data.colors = response.data.colors.map((v, i) => { return { value: i, text: v.name, rgbEval: v.rgbEval} });
        commit("setColors", response.data.colors);        
    }
}

export async function fetchSubcategories({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products/subcategories`;    
    let response = await Axios.get(url, { params: { separator: payload.separator } });
    if(response.data){        
        response.data.subcategories.unshift('Todas');
        response.data.subcategories = response.data.subcategories.map((v, i) => { return { value: i, text: v} });
        commit("setSubcategories", response.data.subcategories);        
    }
}

export async function fetchGenders({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products/genders`;    
    let response = await Axios.get(url, { params: { separator: payload.separator } });
    if(response.data){        
        response.data.genders.unshift('Todos');
        response.data.genders = response.data.genders.map((v, i) => { return { value: i, text: v} });
        commit("setGenders", response.data.genders);        
    }
}

export async function fetchSizes({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products/sizes`;    
    let response = await Axios.get(url, { params: { separator: payload.separator } });
    if(response.data){        
        response.data.sizes.unshift('Todos');
        response.data.sizes = response.data.sizes.map((v, i) => { return { value: i, text: v} });
        commit("setSizes", response.data.sizes);        
    }
}

export async function fetchProductsVariant({ commit }, payload) {
    let url = `${process.env.VUE_APP_API_ENDPOINT}/products/variant`;   
    let response = await Axios.get(url, { params: { gender: payload.gender, size: payload.size, color: payload.color, subcategory: payload.subcategory, separator: payload.separator, page: payload.pageRequested, limit: payload.amountProductsRequested } });
    if(response.data){
        commit("setProducts", response.data.products);
        commit("setProductsCount", response.data.count);        
    }
}

export function setPreviousState({commit}, payload){
    commit("setPreviousState", payload)
}