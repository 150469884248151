<template>

    <v-container class="container-without-carousel" v-if="menuAvailable">
        <v-row class="px-0 mx-0 header-border" justify="center">
            <div v-for="menuItem in menu.filter(p => p.parent_id === null)" :key="menuItem.name" class="mx-2">                
                <div v-if="menu.filter(p => p._id === menuItem._id || p.parent_id !== null).filter(p => p._id !== menuItem._id && p.parent_id == menuItem._id).length">
                    <v-menu offset-y :close-on-content-click="false" open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            text                 
                            v-bind="attrs"
                            v-on="on"                        
                            @click="goToRoute(menuItem)"
                            >
                            {{menuItem.name}}
                            <v-icon
                                dark                              
                                center
                            >
                                    mdi-chevron-down
                                </v-icon>
                            </v-btn>
                        </template>
                        
                        <nodes-list                             
                            :nodes="menu.filter(p => p._id === menuItem._id || p.parent_id !== null)" 
                            :parent_ids="parentIds(menuItem)">
                        </nodes-list>
                    </v-menu>
                </div>
                <div v-else>
                    <v-btn text>
                    {{menuItem.title}}
                    </v-btn>
                </div>
            </div>        
        </v-row>
    </v-container>
    
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NodesList from './NestedNodes'

export default {

    components:{
        'nodes-list': NodesList
    },
    methods: {
        async fetchMenusHandler() {
            try {
                this.menuAvailable = false;
                await this.fetchMenus();
                this.menuAvailable = true;
            } catch(error) {
                this.menuAvailable = false;
            }
        },
        parentIds(menuItem){
            let nodes = this.menu.filter(p => p.parent_id === menuItem._id);
            let parent_ids = [];
            for(let node of nodes){
                parent_ids.push(node._id);
            }
            return parent_ids;
        },
        goToRoute(menuItem){
            const route = "/products/"+menuItem.name;
            if (this.$route.path != route) {
                this.$router.push(route);
            }                            
        },
        ...mapActions("menu", ["fetchMenus"]),
    },
    computed: {
        ...mapGetters("menu", ["menu"]),
    },
    async mounted() {
        await this.fetchMenusHandler();
    },
    watch: {
        $route() {   
            this.fetchMenusHandler();         
        },    
    },
    data() {
        return {
            menuAvailable: false,               
        }
    }
}
</script>


<style scoped>

.header-border{
    border-top: 1px solid gray; 
    border-bottom: 1px solid gray;
}

</style>