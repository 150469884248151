<template>
  <v-row class="top-header ma-0">
    <v-col
      class="pa-0"
      cols="12"
      sm="10"
      md="10"
      align-self="center"
      :class="textTopHeaderClass"
      >São aceites trocas, no prazo de 15 dias após a entrega ☘ Boas
      compras</v-col
    >
    <v-col v-if="isMobile()" cols="6" class="pa-0 pl-3" align="left">
      <v-app-bar-nav-icon
        class="theme--dark"
        @click="appbar = !appbar"
      ></v-app-bar-nav-icon>
    </v-col>
    <v-col
      cols="6"
      sm="2"
      md="2"
      align="right"
      align-self="center"
      class="pa-0 pr-5 cart-wrapper"
    >
      <router-link :to="{ name: 'cart'}"><div class="cart"><v-icon dark>mdi-cart-outline</v-icon><span style="margin-left:5px;">Carrinho {{totalItems > 0 ? ': ' + totalItems : ''}}</span></div></router-link>
    </v-col>

    <v-menu
      :close-on-content-click="false"
      v-model="appbar"
      dark
      attach="#myMenu"
      v-if="menuAvailable"
    >
      <nodes-list
        v-model="appbar"
        :nodes="menu"
        :parent_ids="[null]"
      ></nodes-list>
    </v-menu>
  </v-row>
</template>

<script>
import NestedNodes from "./NestedNodes.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    textTopHeaderClass(){
      if(this.isMobile())
        return ['text-center', 'px-3'];
      return ['px-5'];
    },
    ...mapGetters("menu", ["menu"]),
    ...mapGetters("cart", ["totalItems"])    
  },
  data() {
    return {
      appbar: false,
      windowWidth: window.innerWidth,
      menuAvailable: false    
    };
  },
  methods: {
    ...mapActions("menu", ["fetchMenus"]),
    isMobile() {
      return this.windowWidth < 600;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchMenusHandler(){
      try {
        this.menuAvailable = false;
        await this.fetchMenus();
        this.menuAvailable = true;
      } catch(error) {
          this.menuAvailable = false;
      }
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    
    await this.fetchMenusHandler();    
  },
  watch: {
    $route() {   
      this.fetchMenusHandler();         
    },    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    "nodes-list": NestedNodes,
  },
};
</script>

<style scoped>
.top-header {
  color: white;
  font-family: "PT Serif", sans-serif;
  font-size: 0.85em;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #403b37;
}

.cart-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.theme--dark.v-list {
  background: #403b37;
  border-color: #403b37;
}

.cart {
  display: inline-block;
  white-space: nowrap;
  color: white !important;
}

a {  
  text-decoration: inherit;
}

</style>
