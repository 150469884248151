<template>
  <v-container class="container-without-carousel">
    <v-row class="ma-0">
      <v-col cols="12" sm="6" class="pa-0 ma-0 mt-5">
        <div class="text text-title" :class="{centerText:isMobile}">Fique Ligado</div>
        <v-row class="ma-0 mt-5">
          <ul class="pa-0 ma-0" :class="{centerText:isMobile}">
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://facebook.com/trevodourado.infantil"
                >@trevodourado.infantil<v-icon>mdi-facebook</v-icon></a
              >
            </li>
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://facebook.com/trevodourado.adulto"
                >@trevodourado.adulto<v-icon>mdi-facebook</v-icon></a
              >
            </li>
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://facebook.com/trevodourado.cerimonia"
                >@trevodourado.cerimonia<v-icon>mdi-facebook</v-icon></a
              >
            </li>
          </ul>
        </v-row>
        <v-row class="ma-0">
          <ul class="pa-0 ma-0" :class="{centerText:isMobile}">
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://instagram.com/trevodourado.infantil"
                >@trevodourado.infantil<v-icon>mdi-instagram</v-icon></a
              >
            </li>
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://instagram.com/trevodourado.adulto"
                >@trevodourado.adulto<v-icon>mdi-instagram</v-icon></a
              >
            </li>
            <li class="text pa-0 mr-3 mb-3">
              <a href="https://instagram.com/trevodourado.cerimonia"
                >@trevodourado.cerimonia<v-icon>mdi-instagram</v-icon></a
              >
            </li>
          </ul>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="pa-0 ma-0 mt-5">
        <div class="text text-tile" :class="{centerText:isMobile}">Newsletter</div>
        <p :class="{centerText:isMobile}">Inscreva-se para as notícias, ofertas e estilos mais recentes</p>
        <v-text-field
          label="Email"
          placeholder="o-seu-email@exemplo.com"
          outlined
        ></v-text-field>
        <v-btn color="#e4d69f" class="text subscribe" to="/subscribe">Subscrever</v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-10" justify="center">
      <div class="copyright" style="text-align: center">
        Direitos autorais © 2021, Trevo Dourado.
      </div>
    </v-row>    
  </v-container>
</template>

<script>
export default {
    data(){
        return {

        };
    },
    props: ['isMobile']
};
</script>

<style scoped>
.text-title {
  font-size: 1.5em !important;
}
.social-link {
  font-size: 0.9em !important;
  font-weight: 200;
}
a {
  color: black !important;
  text-decoration: inherit;
}

a:hover {
  opacity: 0.7;
}

.copyright {
  color: black;
}

ul {
  list-style: none outside;
  padding:0;
}

ul li {
  display: inline-block;
}

.centerText{
    text-align: center;
}
</style>
