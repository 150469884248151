import Vue from 'vue'
import VueRouter from 'vue-router'
import Products from "../views/Products.vue";
import ProductDetail from "../views/ProductDetail.vue";
import InPreview from "../views/InPreview.vue";
import Home from "../views/Home.vue";
import Cart from "../views/Cart.vue";
import Order from "../views/Order.vue";
import Store from "../store/index"
import store from '../store'

Vue.use(VueRouter);

const routes = [
    {
        name: 'in-preview',
        path: '/in-preview',
        component: InPreview,
        meta: { requiresToBeLive: false }   
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: { requiresToBeLive: true }   
    },
    {
        name: 'products',
        path: '/products/:id',
        component: Products,
        meta: { requiresToBeLive: true }    
    },
    {
        name: 'productdetail',
        path: '/product-detail/:id',
        component: ProductDetail,
        meta: { requiresToBeLive: true }
    },
    {
        name: 'productdetailvariant',
        path: '/product-detail/:id/variant/:variant',
        component: ProductDetail,
        meta: { requiresToBeLive: true }
    },
    {
        name: 'cart',
        path: '/cart',
        component: Cart,
        meta: { requiresToBeLive: true }
    },
    {
        name: 'order',
        path: '/order',
        component: Order,
        meta: { requiresToBeLive: true },
        beforeEnter: (to, from, next) => {
            if(Store.getters["cart/cart"] && Store.getters["cart/cart"].length > 0){                
                return next();
            }
            return next("/cart")
        }
    }
  ]
  
  const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior () {
        return {x: 0, y: 0};        
    }
  });

  router.beforeEach((to, from, next) => {
    store.dispatch('config/isLive').then(() => {
        
        if(!store.getters["config/isLive"]){
            store.dispatch('config/verify').then(() => {
                if(!store.getters["config/isBypassed"]){
                    return next({
                        path: '/in-preview',                
                    });
                }                
            });
        }

        return next();        
    });    
  });

  export default router