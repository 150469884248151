<template>
  <v-container class="container-without-carousel pt-0">
    <v-row class="ma-0">
      <v-carousel height="auto" eager hide-delimiter-background cycle>
        <v-carousel-item
          v-for="image in bannerImages"
          :key="image"
          :src="image"
          eager
          to="/test"
          class="image-maxed-out"
        >
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row class="mt-10">
        <v-col v-for="store in storeAttrs" :key="store.title" cols="6" sm="4" class="store-single-container cursor">
            <v-img class="image-maxed-out" :src="store.image" @click="redirect(store.title)"/>
            <div class="text store-title">{{store.title}}</div>
        </v-col>
    </v-row>
    <v-row class="ma-0 mt-10">
        <div class="text text-title">Sobre nós</div>
        <div class="text text-content">
            <p>
                Somos um marca portuguesa de retalho de vestuário, acessórios e calçado. No ramo do comércio tradicional desde 1999, distinguimo-nos pelo atendimento personalizado por Dulce Martins. Estamos recentemente presentes no digital, através de um projeto 100% familiar, para ficarmos mais próximos de si.
            </p>
            <p>
                A nossa missão é aliar a entrega de qualidade com a dedicação a cada cliente, tornando cada compra num momento de partilha e companheirismo.
            </p>
            <p>
                Tomando por base valores como o compromisso, ética, transparência, lealdade para com os nossos clientes. Das nossas peças pode esperar originalidade, diversidade, conforto e acima de tudo qualidade.
            </p>
        </div>
    </v-row>
    <v-row class="mt-5">
        <v-col cols="12" sm="6" align-self="center">
            <v-img class="image-maxed-out" :src="require('../assets/marcas_540x.jpg')"/>
        </v-col>
        <v-col cols="12" sm="6">
            <div class="text text-title">As nossas marcas</div>
            <div class="text text-content">
                <p>
                    O Trevo Dourado tem três lojas distintas, onde pode contar com diversas marcas entre portuguesas, espanholas, italianas e francesas, sendo que privilegiamos as nacionais.
                </p>
                <p>
                    Na boutique infantil vendemos a marca Mayoral, Abel & Lula e Gracil.
                </p>
                <p>
                    Dentro da boutique para adulto pode contar com marcas como a Stessa, Liber Jeans, Chic Dress, Tantrend, Pedicaty, Daniela Shoes, Anartxy, entre outras.
                </p>
                <p>
                    Na nossa boutique dedicada aos seus momentos especiais, temos como marcas principais a Moncho Heredia, Betzzia, Carher, Cayro e Kensy.
                </p>
                <p>
                    Algo que é comum a todas estas marcas com que trabalhamos é a qualidade entregue no produto final e a ética na sua produção.
                </p>
            </div>
        </v-col>        
    </v-row>
    <v-row class="ma-0 mt-5 location">
        <v-col cols="12" sm="6" class="py-15" align-self="center">
            <div class="text text-title text-center">As nossas lojas</div>
            <div class="text text-content text-center">
                <p>
                    Avenida 25 de Abril, Centro Comercial Alan-Kerk<br>Loja 12 e 24
                </p>
                <p>
                    Alenquer 2580-367<br>Portugal
                </p>
                <p>
                   Seg. - Sex., 11h - 21h<br>
                   Sábado, 11h - 19h<br>
                   Domingo, Encerrado<br>
                   Não encerramos na hora de almoço.
                </p>
                <v-btn color="#e4d69f" target="_blank" class="text" href="https://maps.google.com/?daddr=Avenida%2025%20Abril,%20Centro%20Comercial%20Alan-Kerk,%20Alenquer"><v-icon>mdi-map-marker</v-icon>Direcções</v-btn>               
            </div>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
            <v-carousel height="auto" eager hide-delimiter-background cycle>
                <v-carousel-item
                v-for="image in storeImages"
                :key="image"
                :src="image"
                eager
                class="image-maxed-out"
                >
                </v-carousel-item>
            </v-carousel>
        </v-col>                
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
      urlToGo(storeTitle){
          const storeMenu = this.menu.find(p => p.name === storeTitle);
          if(storeMenu){
              return '/products/' + storeMenu.name;
          }
          return '/';
      },
      redirect(storeTitle){
        const newRoute = this.urlToGo(storeTitle);
        if (this.$route.path != newRoute) {
            this.$router.push(newRoute);
        }
      },
  },
  data() {
    return {
      bannerImages: [
        require('../assets/banner1.jpeg'),
        require('../assets/banner2.jpeg'),
        require('../assets/banner3.jpeg'),
        require('../assets/banner4.jpeg'),
        require('../assets/banner5.jpeg'),
        require('../assets/banner6.jpeg'),
        require('../assets/banner7.jpeg'),
        require('../assets/banner8.jpeg'),
        require('../assets/banner9.jpeg'),
        require('../assets/banner10.jpeg'),
        require('../assets/banner11.jpeg'),
        require('../assets/banner12.jpeg'),
      ],
      storeAttrs: [
          { title: "Infantil", image: require('../assets/infantil_540x.jpeg') },
          { title: "Adulto", image: require('../assets/adulto_540x.jpeg') },
          { title: "Cerimónia", image: require('../assets/cerimonia_540x.jpeg')},
      ],
      storeImages: [
           require('../assets/store1_1080x.jpg'),
           require('../assets/store2_1080x.jpg'),
           require('../assets/store3_1080x.jpg')
      ],      
    };
  },
  computed: {
    ...mapGetters("menu", ["menu"]),
  },
};
</script>

<style scoped lang="scss">

.store-single-container {
    text-align: center;
}

.store-title {    
    font-size: 1.8em !important;    
    margin-top: 10px;
}

.cursor:hover {
    cursor: pointer;
    opacity: 0.7;    
}

.location {
    background-color: #f4f4f4
}


</style>
