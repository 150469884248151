<template>
  <v-container
    :style="{ 'max-width': '1340px', padding: '30px' }"
    v-if="hasProductsData && !ajaxError"
  >
    <v-row>
      <v-col        
        cols="12"
        sm="2"        
        align-self="center"
        justify="center"
        :align="$vuetify.breakpoint.xsOnly ? 'center' : ''"     
      >
      <span style="background-color: white; border:3px solid #e4d69f; border-radius: 5px; color: black; padding: 12px 30px;height:100%;">
        {{ $route.params.id }}
      </span>      
      
      </v-col>
      <v-spacer/>
      <v-col        
        cols="12"
        sm="2"
        
        
      >
        <v-select
              v-if="genders.length > 1"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="genders"
              v-model="defaultGender"
              hide-details
              @change="updateProducts(1)"
              label="Género"
              outlined
        ></v-select>
      </v-col>

      <v-col        
        cols="12"
        sm="2"
        
        
      >
        <v-select
              v-if="subcategories.length > 1"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="subcategories"
              v-model="defaultSubcategorySelected"
              hide-details
              @change="updateProducts(1)"
              label="Categoria"
              outlined
        ></v-select>
      </v-col>
      <v-col        
        cols="12"
        sm="2"
      >
        <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :items="sizes"
              v-model="defaultSizeSelected"
              hide-details
              @change="updateProducts(1)"
              label="Tamanhos"
              outlined
        ></v-select>
      </v-col>
      <v-col        
        cols="12"
        sm="2"
      >
        <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :items="colors"
              v-model="defaultColorSelected"
              hide-details
              @change="updateProducts(1)"
              label="Padrão"
              outlined
        >
          <template v-if="defaultColorSelected !== 0" v-slot:prepend-inner>            
            <div :style="'border-radius:5px;border:1px solid black;background-color:'+colors[defaultColorSelected].rgbEval + ';height:20px;width:30px;'"></div>
          </template>
          <template v-slot:item="{ item }">                        
             <div v-if="item.rgbEval" style="display:flex;justify-content:center;">
               <div :style="'border-radius:5px;border:1px solid black;display:inline-block;background-color:'+item.rgbEval+';height:20px;width:30px;'"></div>
               <div style="display:inline-block;margin-left:5px;">{{item.text}}</div>               
             </div>
             <div v-else>{{item.text}}</div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="product in products"
        :key="product.ref+product.color"
        cols="6"
        sm="3"
        class="col-md-2-5"
        style="position:relative;"
      >
        <div v-if="product.price_final !== product.price" class="promoCircle">PROMOÇÃO</div>
        <router-link
          style="text-decoration:none;"
          :to="{ name: 'productdetailvariant', params: { id: product.ref, variant: product.id } }"
        >
          
          <v-img
            :src="mediumImage(product.photos[0])"
            style="max-width: 100%; width: auto; height: auto;"
          >
            
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  :size="100"
                  color="#e4d69f"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </router-link>

        <p class="title">{{ product.title }}</p>
        <p class="brand">{{ product.brand }}</p>
        <p class="price">
          <span class="price-final"
            >{{ product.price_final.toFixed(2).replace(".", ",") }} €</span
          >
          <span
            v-if="product.price_final !== product.price"
            class="price-no-discount"
            >{{ product.price.toFixed(2).replace(".", ",") }} €</span
          >
        </p>
      </v-col>
    </v-row>
    
    <v-row
      align="center"
      justify="center"
      v-if="Math.ceil(productsCount / 25) > 0"
      class="mt-10"
    >
      <div class="text-center">
        {{page}}
        <v-pagination
          v-model="page"
          :length="Math.ceil(productsCount / 25)"
          total-visible="5"
          color="#e4d69f"
          circle
          @input="updateProducts"
        ></v-pagination>
      </div>
    </v-row>
  </v-container>
  <v-container
    :style="{ 'max-width': '1340px', padding: '30px' }"
    v-else-if="!hasProductsData && !ajaxError"
  >
    <div class="text-center">
      <v-progress-circular
        :size="200"
        color="#e4d69f"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
  <v-container
    :style="{ 'max-width': '1340px', padding: '30px' }"
    v-else-if="ajaxError"
  >
    <v-row justify="center" align="center">
      <v-img
        :src="require('../assets/error.svg')"
        contain
        width="60"
        height="60"
      />
    </v-row>
    <v-row justify="center" align="center">
      <span class="text-content error-msg"
        >Pedimos desculpa mas ocorreu um erro.</span
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("product", ["products", "productsCount", "subcategories", "genders", "colors", "sizes", "previousSize", "previousColor", "previousSubcategory"]),
  },
  methods: {
    ...mapActions("product", ["fetchProducts", "fetchColors", "fetchSubcategories", "fetchGenders", "fetchSizes", "fetchProductsVariant", "setPreviousState"]),
    async updateProducts(page, color, subcategory, size, gender) {
      try {
        this.hasProductsData = false;
        this.ajaxError = false;                
        await this.fetchProductsBasedColorSubcategorySelector(
          color === undefined ? this.defaultColorSelected : color,
          subcategory === undefined ? this.defaultSubcategorySelected : subcategory,
          size === undefined ? this.defaultSizeSelected : size,
          gender === undefined ? this.defaultGender : gender,
          page === 0 ? page : page-1
        );                
        this.hasProductsData = true;
        this.ajaxError = false;
      } catch (error) {
        this.hasProductsData = false;
        this.ajaxError = true;
      }
    },
    async fetchGendersFn(){
      await this.fetchGenders({
          separator: this.$route.params.id,
      });

      if(this.genders.length){        
        this.defaultGender = this.genders[0].value;
      }

    },
    async fetchSubcategoriesFn(){
      await this.fetchSubcategories({
          separator: this.$route.params.id,
      });

      if(this.subcategories.length){        
        this.defaultSubcategorySelected = this.subcategories[0].value;
      }
    },
    async fetchColorsFn(){
      await this.fetchColors({
          separator: this.$route.params.id,
      });      
      this.defaultColorSelected = this.colors[0].value;
    },
    async fetchSizesFn(){
      await this.fetchSizes({
          separator: this.$route.params.id,
      });
      if(this.sizes.length){        
        this.defaultSizeSelected = this.sizes[0].value;
      }
    },
    async fetchProductsBasedColorSubcategorySelector(newColor, newSubcategory, size, gender, page){       
      await this.fetchProductsVariant({
        color: !newColor ? null : this.colors[newColor].text,
        subcategory: !newSubcategory ? null : this.subcategories[newSubcategory].text,
        size: !size ? null: this.sizes[size].text,
        gender: !gender ? null: this.genders[gender].text,
        separator: this.$route.params.id,        
        pageRequested: page || 0,
        amountProductsRequested: 25,
      });              
    },
    mediumImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_m.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_m.png');
      else if(url.includes('.jpeg'))
        return url.replace('.jpeg', '_m.jpeg');
      return url;
    }  
  },
  beforeDestroy(){
    this.setPreviousState({
      size: this.defaultSizeSelected,
      color: this.defaultColorSelected,
      subcategory: this.defaultSubcategorySelected,
      gender: this.defaultGender
    });     
  },
  mounted() {        
    const initialConfigurations = [];
    initialConfigurations.push(this.fetchColorsFn());
    initialConfigurations.push(this.fetchSubcategoriesFn());
    initialConfigurations.push(this.fetchGendersFn());
    initialConfigurations.push(this.fetchSizesFn());         
    Promise.all(initialConfigurations)
    .then(() => {
      this.defaultColorSelected = this.previousColor;    
      this.defaultSubcategorySelected = this.previousSubcategory;
      this.defaultSizeSelected = this.previousSize;
    })
    .then(() => this.updateProducts(0, this.defaultColorSelected, this.defaultSubcategorySelected, this.defaultSizeSelected, this.defaultGender));
  },
  watch: {
    $route() { 
      this.hasProductsData = false;
      const initialConfigurations = [];
      initialConfigurations.push(this.fetchColorsFn());
      initialConfigurations.push(this.fetchSubcategoriesFn());
      initialConfigurations.push(this.fetchGendersFn());
      initialConfigurations.push(this.fetchSizesFn());         
      Promise.all(initialConfigurations)
      .then(() => this.updateProducts(0, this.defaultColorSelected, this.defaultSubcategorySelected, this.defaultSizeSelected, this.defaultGender));     
    },
    defaultColorSelected(){      
      this.page = 1;
    },
    defaultSubcategorySelected(){
      this.page = 1;
    },
    defaultGender(){
      this.page = 1;
    },
    defaultSizeSelected(){
      this.page = 1;
    }
  },
  data() {
    return {
      page: 1,
      defaultColorSelected: 0,
      defaultSubcategorySelected: 0,
      defaultSizeSelected: 0,
      defaultGender: 0,
      hasProductsData: false,
      ajaxError: false,
    };
  },
};
</script>


<style scoped lang="scss">
.brand {
  font-size: 0.8em;
  font-weight: 400;
  color: #403b37;
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  line-height: 1.4;
}

.price {
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  line-height: 1.5;
  color: #5b544f;
}

.title {
  color: #403b37;
  font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
  font-size: 0.8em;
  line-height: 1.4;
  font-weight: 700;
  margin-top: 10px;
}

p {
  margin: 0 0 5px 0;
}

.promoCircle {
    width: 64px;
    line-height: 64px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -16px;
    top: -16px;
    font-size: 10px;   
    z-index: 5; 
    background-color: #e4d69f;
    font-weight: bold;
}

.promoCircle:link {
    text-decoration: none;
}
.promoCircle:hover {
    text-decoration: none;
}

</style>
