<template>
  <v-container :class="containerClass" v-if="hasProductData && !ajaxError">
    <v-carousel height="100vh" v-model="selectedImageIndex" v-if="showCarousel">
      <v-btn fab fixed right dark class="mt-8 mr-8" @click="toggleCarousel()">
        <v-icon dark center>mdi-close</v-icon>
      </v-btn>

      <v-carousel-item
        v-for="(image, i) in carouselImagesLarge"
        :key="i"
        eager
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-row align="center" justify="center" style="height: 100%" no-gutters>
          <img :src="image" class="zoom-out" @click="toggleCarousel()" />
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <v-row v-else-if="!showCarousel">
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" align-self="stretch">
            <v-img
              :src="mainProductLink"
              class="image-maxed-out zoom-in"
              @click="toggleCarousel()"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    :size="100"
                    color="amber"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" v-for="(image, i) in carouselImagesMedium" :key="image">
            <v-img
              :src="image"
              class="image-maxed-out pointer"
              @click="selectAnotherImage(i)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    :size="100"
                    color="amber"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <v-form v-model="valid" ref="form">
          <p class="brand">{{ product.brand }}</p>
          <p class="title">{{ product.title }}</p>
          <p class="ref">Referência: {{ product.ref }}</p>
          <p class="price">
            <span class="price-final">{{priceFinal}} €</span>
            <span v-if="showOldPrice" class="price-no-discount">{{price}} €</span>
          </p>
          <p class="tax">Imposto incluído.</p>

          <v-col class="d-flex pa-0 ma-0" cols="12" sm="6" md="3">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :items="product.colors"
              v-model="defaultColorSelected"              
              label="Cor"
              outlined
            >
              <template v-slot:prepend-inner>
                <div :style="'border-radius:5px;border:1px solid black;background-color:'+product.variants.find(p => p.color === defaultColorSelected).rgbEval+';height:20px;width:30px;'"></div>
              </template>
              <template v-slot:item="{ item }">                        
                <div v-if="product.variants.find(p => p.color === defaultColorSelected) && product.variants.find(p => p.color === defaultColorSelected).rgbEval" style="display:flex;justify-content:center;">
                  <div :style="'border-radius:5px;border:1px solid black;display:inline-block;background-color:'+product.variants.find(p => p.color === item).rgbEval+';height:20px;width:30px;'"></div>
                  <div style="display:inline-block;margin-left:5px;">{{item}}</div>               
                </div>
                <div v-else>{{item}}</div>
              </template>
            </v-select>
          </v-col>

          <v-col class="d-flex pa-0 ma-0" cols="12" sm="6" md="3">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :items="sizesToSelect"
              v-model="defaultSizeSelected"
              item-value="_id"
              item-text="size"
              label="Tamanho"
              outlined
            ></v-select>
          </v-col>

          <v-col class="d-flex pa-0 ma-0" cols="12" sm="6" md="3">
            <v-text-field
              v-model.number="quantity"
              outlined
              label="Quantidade"
              type="number"
              min="1"
              :rules="[rules.quantityValidator]"    
            />
          </v-col>
          

          <v-col class="d-flex pa-0 ma-0" cols="12" sm="6" md="3">
            <v-btn
              :disabled="!valid"
              color="#e4d69f"
              class="mr-4"
              @click="addToCartBtn"
            >
              {{addToCartText}}
            </v-btn>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    :class="containerClass"
    v-else-if="!hasProductData && !ajaxError"
  >
    <div class="text-center">
      <v-progress-circular
        :size="200"
        color="#e4d69f"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
  <v-container :class="containerClass" v-else-if="ajaxError">
    <v-row justify="center" align="center">
      <v-img
        :src="require('../assets/error.svg')"
        contain
        width="60"
        height="60"
      />
    </v-row>
    <v-row justify="center" align="center">
      <span class="text-content error-msg"
        >Pedimos desculpa mas ocorreu um erro.</span
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {  
    ...mapGetters("product", ["product"]),
    ...mapGetters("cart", ["cart"]),
    priceFinal(){      
      return this.product.variants.filter((v) => v._id === this.defaultSizeSelected)[0].price_final.toFixed(2).replace('.',',')
    },
    price(){      
      return this.product.variants.filter((v) => v._id === this.defaultSizeSelected)[0].price.toFixed(2).replace('.',',')      
    },
    showOldPrice(){      
      return this.product.variants.filter((v) => v._id === this.defaultSizeSelected)[0].price_final !== this.product.variants.filter((v) => v._id === this.defaultSizeSelected)[0].price;
    },
    sizesToSelect(){
      return this.product.variants.filter((v) => v.color === this.defaultColorSelected);
    },
    originalCarouselImages(){
      return this.product.variants.filter(
        (p) => p.color === this.defaultColorSelected
      )[0].photos;
    },
    carouselImagesLarge() {
      return this.originalCarouselImages.map(url => this.xlargeImage(url));
    },
    carouselImages() {
      return this.originalCarouselImages.map(url => this.largeImage(url));
    },
    carouselImagesMedium() {
      return this.originalCarouselImages.map(url => this.mediumImage(url));
    },
    mainProductLink() {      
      return this.largeImage(this.product.variants.filter(
        (p) => p.color === this.defaultColorSelected
      )[0].photos[this.selectedImageIndex]);
    },
    containerClass() {
      if (!this.showCarousel) return "container-without-carousel";
      return "container-with-carousel";
    },
    addToCartText(){
      if(!this.valid){
        return "Esgotado";
      }
      return "Encomendar";
    }
  },
  methods: {
    ...mapActions("product", ["fetchProduct"]),
    ...mapActions("cart", ["addToCart"]),
    selectAnotherImage(i) {
      this.selectedImageIndex = i;
    },
    toggleCarousel() {
      if (!this.isMobile()) {
        this.showCarousel = !this.showCarousel;
        this.$emit("toggleMenu");
      }
    },
    isMobile() {
      return this.windowWidth < 600;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async updateProductUsingId(id){
      try {
        
        this.ajaxError = false;
        this.hasProductData = false;
        
        await this.fetchProduct(id);
        
        if(this.$route.params.variant){
          let variantSelected = this.product.variants.find((p) => p.id.toString() === this.$route.params.variant.toString());
          
          this.defaultColorSelected = variantSelected.color;
          this.defaultSizeSelected = variantSelected._id;                  
          this.routeEnterFromDetailed = true;
        } else {
          this.defaultColorSelected = this.product.colors[0];          
          this.defaultSizeSelected = this.product.variants.filter((p) => p.color === this.defaultColorSelected)[0]._id;
        }        
        
        if(this.product.variants.some(p => p.qty > 0)){
          this.hasProductData = true;
          this.ajaxError = false;        
        } else {
          this.hasProductData = false;
          this.ajaxError = true;
        }
        
      } catch (error) {
        console.log(error);
        this.hasProductData = false;
        this.ajaxError = true;
      }
    },
    addToCartBtn(){      
      this.addToCart({product: this.product, variant: this.product.variants.find(p => p._id === this.defaultSizeSelected), quantity: this.quantity, mainProductId: this.$route.params.id});      
      this.$router.push('/cart');
    },
    largeImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_l.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_l.png');
      else if(url.includes('.jpeg'))
        return url.replace('.jpeg', '_l.jpeg');
      return url;
    },
    xlargeImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_x.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_x.png');
      else if(url.includes('.jpeg'))
        return url.replace('.jpeg', '_x.jpeg');
      return url;
    },
    mediumImage(url){
      if(url.includes('.jpg'))
        return url.replace('.jpg', '_m.jpg');
      else if(url.includes('.png'))
        return url.replace('.png', '_m.png');
      else if(url.includes('.jpeg'))
        return url.replace('.jpeg', '_m.jpeg');
      return url;
    } 
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });    
  },
  updated(){
    if(this.$refs.form)
      this.$refs.form.validate();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  async created(){
    await this.updateProductUsingId(this.$route.params.id);
    this.routeEnterFromDetailed = false;      
  },
  watch: {    
    defaultColorSelected(newColor) {
      
      this.selectedImageIndex = 0;      

      const routeUrl = '/product-detail/'+this.$route.params.id+'/variant/'+this.product.variants.find(p => p.color === newColor).id;
      if (this.$route.path != routeUrl) {
        this.$router.push(routeUrl);
      } 

      // if(!this.routeEnterFromDetailed){
      //   console.log(newColor);
      //   this.defaultSizeSelected = this.product.variants.filter(
      //     (p) => p.color === newColor
      //   )[0]._id;
      // }
    },
    defaultSizeSelected(newSize) {
      const routeUrl = '/product-detail/'+this.$route.params.id+'/variant/'+this.product.variants.find(p => p._id === newSize).id;
      if (this.$route.path != routeUrl) {
        this.$router.push(routeUrl);
      }      
    },
    $route () {
      this.updateProductUsingId(this.$route.params.id);
      this.routeEnterFromDetailed = false;
    }
  },
  data() {
    return {      
      routeEnterFromDetailed: false,    
      windowWidth: window.innerWidth,
      hasProductData: false,
      ajaxError: false,
      valid: false,
      showCarousel: false,
      selectedImageIndex: 0,
      defaultSizeSelected: 0,
      defaultColorSelected: 0,
      quantity: 1,
      rules: {
        quantityValidator: (value) => {

          let amountExisting = this.product.variants.filter(
              (p) => p._id === this.defaultSizeSelected
            )[0].qty;

          let existsInCart = this.cart.filter(p => p.variant._id === this.defaultSizeSelected).length > 0;
          return (            
            amountExisting >= value && (!existsInCart || existsInCart && amountExisting >= this.cart.filter(p => p.variant._id === this.defaultSizeSelected)[0].quantity + value)
          );
        },
      },
    };
  },
};
</script>


<style scoped lang="scss">
.v-application p {
  &.brand {
    font-size: 1.375em;
    font-weight: 700;
    color: #403b37;
    font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif;
    line-height: 1.2;
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  &.title {
    color: #403b37;
    font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;
    font-size: 2em !important;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  &.price {
    font-family: "Optima nova LT Pro Light";
    font-size: 22.4px;
    letter-spacing: 1.2px;
    line-height: 1.5;
    color: #8cc2e1;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  &.tax {
    font-family: "PT Sans", sans-serif;
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }
}
</style>
