<template>
  <div id="app">
    <v-app id="app">      
      <v-main>
        <top-header v-if="showHeader && (isLive || isBypassed)"/>
        <div id="myMenu" style="position: relative" app></div>
        <banner v-if="showHeader"></banner>
        <menu-header v-if="!isMobile() && showHeader && (isLive || isBypassed)"></menu-header>
        <transition name="fade" mode="out-in">
          
          <router-view @toggleMenu="showHeader = !showHeader"/>
          
        </transition>
        <bottom-footer v-if="showHeader && (isLive || isBypassed)" :isMobile="isMobile()"></bottom-footer>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import TopHeader from "./components/TopHeader.vue";
import MenuHeader from "./components/MenuHeader.vue";
import Banner from "./components/Banner.vue";
import BottomFooter from "./components/Footer.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showHeader: true,
      appbar: false,
      windowWidth: window.innerWidth,
      items: [
        {
          action: "local_activity",
          title: "Attractions",
          items: [{ title: "List Item" }],
        },
        {
          action: "restaurant",
          title: "Dining",
          active: false,
          items: [
            {
              title: "Breakfast & brunch",
              items: [{ title: "test1" }, { title: "test2" }],
            },
            { title: "New American" },
            { title: "Sushi" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("config", ["isLive", "isBypassed"]),
  },
  components: {
    'top-header': TopHeader,
    'menu-header': MenuHeader,
    'banner': Banner,
    'bottom-footer': BottomFooter
  },
  methods: {    
    isMobile() {
      return this.windowWidth < 600;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.top-header {
  color: white;
  font-family: "PT Serif", sans-serif;
  font-size: 0.85em;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #403b37;
}

.cart-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.theme--dark.v-list {
  background: #403b37;
  border-color: #403b37;
}

.cart {
  min-width: 70px;
}
</style>

<style lang="scss">

@font-face {
  font-family: 'Optima nova LT Pro Light';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/OptimaNovaLTProLight.woff') format('woff');
}

.container-without-carousel {
  max-width: 1340px;
  padding: 30px;
}

.container-with-carousel {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 0px;
}

.image-maxed-out {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 960px){
.col-md-2-5 {
    flex: 0 0 20%;
    max-width: 20%;
}
}

.zoom-in {cursor: zoom-in;}
.zoom-out {cursor: zoom-out; max-width: 100%; max-height: 100%;}
.pointer {cursor: pointer;}

.text {
    color: black;
    font-family: "Optima nova LT Pro Light", "PT Serif", sans-serif !important;    
    line-height: 1.2;
    font-weight: 800;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

.text-title {
    font-size: 1.8em !important;
    font-weight: 700;
}

.text-content {
    margin-top: 15px;
    font-size: 1.2em !important;
    font-weight: 500;
}

.price-final {
  font-size: 1.1em;
}

.price-no-discount {
  color: rgba(grey, 0.8);
  font-size: 0.9em;
  text-decoration: none;
  position: relative;
  margin-left: 10px;
  &:before {
    content: " ";
    display: block;
    width: 100%;
    border-top: 1px solid rgba(red, 0.8);
    height: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-7deg);
  }
}

</style>
