export function addProductToCart(state, val) {
    let existingVariant = state.cart.filter(p => p.variant._id === val.variant._id);
    if(existingVariant.length){
        existingVariant[0].quantity = existingVariant[0].quantity + val.quantity;
    } else {
        state.cart.push(val);
    }    
}

export function removeProductById(state, val) {
    let index = state.cart.findIndex(p => p.variant._id === val._id);
    if(index >= 0){
        state.cart.splice(index, 1);
    }
}

export function updateProductQuantity(state, val) {
    let item = state.cart.find(p => p.variant._id === val._id);
    if(item) {
        item.quantity = val.quantity;
    }
}

export function saveCart(state){
    window.localStorage.setItem('cart', JSON.stringify(state.cart));    
}

export function success(state){
    state.success = true;
}

export function error(state, msg){
    state.error = true;
    state.errorMsg = msg ? msg : 'Ocorreu um erro de stock a submeter a sua encomenda! Tente novamente.';
}

export function clearSendOrderState(state){
    state.success = false;
    state.errorMsg = '';
    state.error = false;
}

export function clearCart(state){
    window.localStorage.removeItem('cart');
    state.cart = [];
}