<template>
    <v-container class="container-without-carousel">
        <v-row justify="center" class="ma-0">            
            <v-img contain class="image-maxed-out" max-width="400" style="cursor:pointer;" :src="require('../assets/banner.jpg')" @click="redirect"/>
        </v-row>                
    </v-container>
</template>

<script>
export default {
    methods: {
        redirect() {
            if (this.$route.path != "/") {
                return this.$router.push('/');
            }            
        }
    }
}
</script>

<style scoped>

</style>
