export function cart (state) {
    return state.cart
}

export function totalItems (state) {
    return state.cart.reduce((acc, current ) => { return acc + parseInt(current.quantity) }, 0);
}

export function totalPrice (state) {
    return state.cart.reduce((acc, current ) => { return acc + current.quantity * current.variant.price_final }, 0);
}

export function success(state) {
    return state.success;
}

export function error(state) {
    return state.error;
}

export function errorMsg(state) {
    return state.errorMsg;
}