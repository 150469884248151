export function isLive (state) {
    return state.isLive;
}

export function isBypassed (state) {
    return state.isByPassed;
}

export function shippingCost(state) {
    return state.shippingCost;
}

